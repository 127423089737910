import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';

export class FormJournalRapportage extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),
            ridPrest: null,
            retatObj: null,
            rstatutPay: null,
            liste_data: [],
            liste_selectedFacture: [],
            listeprest: [],
            totalFacture: '0',
            totalRest: '0',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
                {
                    label: 'Editer factures',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_factures();
                    }
                },
                {
                    label: 'Editer rapportage',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_rapportages();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.conf_print_factures = this.conf_print_factures.bind(this);
        this.conf_print_rapportages = this.conf_print_rapportages.bind(this);
        this.FbrService = new FbrService();
    }

    conf_print_rapportages() {
        let ids = "";
        if (this.state.liste_selectedFacture !== null && this.state.liste_selectedFacture !== undefined && this.state.liste_selectedFacture.length > 0) {
            ids = this.state.liste_selectedFacture.map(x => {
                return x.idObj
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idObj
            }).join("_");
        }
        this.FbrService.generer_edition_rapportage(ids, 'PDF');
    }

    conf_print_factures() {
        let ids = "";
        if (this.state.liste_selectedFacture !== null && this.state.liste_selectedFacture !== undefined && this.state.liste_selectedFacture.length > 0) {
            ids = this.state.liste_selectedFacture.map(x => {
                return x.idObj
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idObj
            }).join("_");
        }
        this.FbrService.generer_edition_facture(ids, 'PDF');
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let ridpres = 'Tous';
        let retat = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.retatObj !== undefined && this.state.retatObj !== null) {
            retat = this.state.retatObj;
        }
        if (this.state.rstatutPay !== undefined && this.state.rstatutPay !== null) {
            retat = this.state.rstatutPay;
        }

        this.FbrService.generer_edition_journal_rapportage(spd1, spd2, ridpres, retat, rstatut, format);
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest }
                    });
                    this.setState({ listeprest: liste });
                }
            }
        });
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let ridpres = 'Tous';
        let retat = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.retatObj !== undefined && this.state.retatObj !== null) {
            retat = this.state.retatObj;
        }
        if (this.state.rstatutPay !== undefined && this.state.rstatutPay !== null) {
            retat = this.state.rstatutPay;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_liste_objectif(spd1, spd2, ridpres, retat, rstatut).then(data => this.setState({ liste_data: data }, () => {
            this.setState({ showindicator: false });
        }));
    }

    componentDidMount() {
        this.get_liste_prestataire();
        this.get_liste_data();
    }


    render() {
        return (
            <div className="card p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={
                                        [{ label: 'Etat', value: 'Tous' },
                                        { label: 'En attente', value: 'En attente' },
                                        { label: 'Validé', value: 'Validé' }
                                        ]} optionLabel='label' placeholder='Etat' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.retatObj} onChange={e => this.setState({ retatObj: e.value }, () => {
                                            this.get_liste_data();
                                        })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={
                                        [{ label: 'Payement', value: 'Tous' },
                                        { label: 'Payée', value: 'Payée' },
                                        { label: 'Non', value: 'Non payée' }
                                        ]} optionLabel='label' placeholder='Statut payement' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.retatObj} onChange={e => this.setState({ retatObj: e.value }, () => {
                                            this.get_liste_data();
                                        })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <DataTable value={this.state.liste_data} selection={this.state.liste_selectedFacture} onSelectionChange={e => this.setState({ liste_selectedFacture: e.value })}
                    responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";" responsive={true} ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    <Column field="idObj" header="Numero" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="periodeObj" header="Période" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="statutPayObj" header="Statut" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="nomPrest" header="Acteur" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="regionSite" header="Region" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="prefectureSite" header="Prefecture" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="scoreObj" header="Score (%)" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="margeError" header="Marge error (%)" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '14%' }} />
                </DataTable>
            </div>
        );
    }
}
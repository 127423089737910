import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { ColumnGroup } from 'primereact/columngroup';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
import { Row } from 'primereact/row';

export class FormJournalFactureTrim extends Component {
    constructor() {
        super();
        this.state = {
            rannee: moment(new Date()).format('YYYY'),
            rRegion: null,
            rPref: null,
            rCrd: null,
            liste_data: [],
            liste_selectedFacture: [],
            totalFacture: '0',
            totalT1: '0',
            totalT2: '0',
            totalT3: '0',
            totalT4: '0',

            liste_region: [],
            liste_annee: [{ label: moment(new Date()).format('YYYY'), value: moment(new Date()).format('YYYY') }],
            liste_all_pref: [],
            liste_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            items: [
                {
                    label: 'Liste format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Liste format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Liste format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
                {
                    label: 'Factures T1',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_facture('T1');
                    }
                },
                {
                    label: 'Factures T2',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_facture('T2');
                    }
                },
                {
                    label: 'Factures T3',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_facture('T3');
                    }
                },
                {
                    label: 'Factures T4',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_facture('T4');
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.FbrService = new FbrService();
    }


    conf_print_facture(trim) {
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let ids = "";
        if (this.state.liste_selectedFacture !== null && this.state.liste_selectedFacture !== undefined && this.state.liste_selectedFacture.length > 0) {
            ids = this.state.liste_selectedFacture.map(x => {
                return x.idPrest
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idPrest
            }).join("_");
        }
        this.FbrService.generer_facture_trim(ids,this.state.rannee,trim);
    }

    conf_print(format) {
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        this.FbrService.generer_tt_facture_trim_prest(this.state.rannee, region, pref, crd, format);
    }

    get_liste_data() {
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_tt_facture_trim_prest(this.state.rannee, region, pref, crd).then(data => {
            this.setState({ liste_data: data, showindicator: false })
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObj
                    }, initialValue);

                    let tt1 = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObjT1
                    }, initialValue);
                    let tt2 = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObjT2
                    }, initialValue);
                    let tt3 = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObjT3
                    }, initialValue);
                    let tt4 = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObjT4
                    }, initialValue);

                    this.setState({ 
                        totalFacture: this.FbrService.formaterValueSep(tt),
                        totalT1: this.FbrService.formaterValueSep(tt1),
                        totalT2: this.FbrService.formaterValueSep(tt2),
                        totalT3: this.FbrService.formaterValueSep(tt3),
                        totalT4: this.FbrService.formaterValueSep(tt4)
                    });
                }
            }
        });
    }

    componentDidMount() {
        this.FbrService.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });
        this.FbrService.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });
        this.get_liste_data();

        this.setState({ liste_region: this.FbrService.get_liste_regions() });

        this.FbrService.get_liste_annee().then(data => {
            if (data != null && data != undefined) {
                if (data.length > 0) {
                    this.setState({ liste_annee: data });
                }
            }
        });
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        this.setState({ liste_pref: liste });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        this.setState({ liste_crd: liste });
    }


    render() {

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={4} footerStyle={{ textAlign: 'center',fontWeight: 'bold' }} />
                <Column footer={this.state.totalT1}  style={{ color: '#000000', fontWeight: 'bold' }}/>
                <Column footer={this.state.totalT2} style={{ color: '#000000', fontWeight: 'bold' }}/>
                <Column footer={this.state.totalT3} style={{ color: '#000000', fontWeight: 'bold' }}/>
                <Column footer={this.state.totalT4} style={{ color: '#000000', fontWeight: 'bold' }}/>
                <Column footer={this.state.totalFacture} style={{ color: '#000000', fontWeight: 'bold' }}/>
            </Row>
        </ColumnGroup>;

        return (
            <div className="card p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                                    <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={this.state.liste_annee} optionLabel='label' placeholder='Année' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rannee} onChange={e => this.setState({ rannee: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={[{ label: 'Region', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='Region' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_pref(e.value);
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown autoWidth={false} options={[{ label: 'Préfecture', value: 'Tous' }].concat(this.state.liste_pref)} optionLabel='label' placeholder='Préfecture' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rPref} onChange={e => this.setState({ rPref: e.value }, () => {
                                        this.get_liste_data();
                                        this.setState({ liste_crd: [] });
                                        this.select_crd(e.value);
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'CR', value: 'Tous' }].concat(this.state.liste_crd)} optionLabel='label' placeholder='CR' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rCrd} onChange={e => this.setState({ rCrd: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <div className="p-inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable value={this.state.liste_data} selection={this.state.liste_selectedFacture} onSelectionChange={e => this.setState({ liste_selectedFacture: e.value })}
                    responsive={true} scrollable={true} footerColumnGroup={footerGroup}  scrollHeight="570px" csvSeparator=";"  ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    <Column field="ordre" header="#" style={{ width: '5%' }} filter={true} filterMatchMode='contains' selection={this.state.liste_selectedFacture} onSelectionChange={e => this.setState({ liste_selectedFacture: e.value })} />
                    <Column field="nomPrest" header="Acteur" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="prefectureSite" header="Prefecture" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mttObjT1" header="MT T1" body={(rowData, column) => {
                        return <div>
                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.mttObjT1))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mttObjT2" header="MT T2" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.mttObjT2))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mttObjT3" header="MT T3" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.mttObjT3))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mttObjT4" header="MT T4" body={(rowData, column) => {
                        return <div>
                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.mttObjT4))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mttObj" header="Total" body={(rowData, column) => {
                        return <div>
                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.mttObj))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                </DataTable>

                
            </div>
        );
    }
}
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppMenu from './AppMenu';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';

import { Dashboard } from './components/Dashboard';
import { FormUtilisateur } from './components/FormUtilisateur';
import { FormSite } from './components/FormSite';
import { FormPrestataire } from './components/FormPrestataire';
import { FormFormuleEvaLigneFormule } from './components/FormFormuleEvaLigneFormule';
import { FormTypeService } from './components/FormTypeService';
import { FormCategoriePrest } from './components/FormCategoriePrest';
import { FormFamilleIndicateur } from './components/FormFamilleIndicateur';
import { FormBanque } from './components/FormBanque';
import { FormReglerObjectif } from './components/FormReglerObjectif';
import { FormFacture } from './components/FormFacture';
import { FormJournalFacture } from './components/FormJournalFacture';
import { FormJournalRapportage } from './components/FormJournalRapportage';
import { FormJournalReco } from './components/FormJournalReco';
import { FormJournalTrace } from './components/FormJournalTrace';
import { FormJournalPayement } from './components/FormJournalPayement';
import { FormStatRapportagePrest } from './components/FormStatRapportagePrest';
import { FormStatRapportageCRD } from './components/FormStatRapportageCRD';
import { FormStatRapportagePref } from './components/FormStatRapportagePref';
import { FormStatRapportageRegion } from './components/FormStatRapportageRegion';
import { FormChpwd } from './components/FormChpwd';
import { FormTypePrest } from './components/FormTypePrest';
import { FormArticle } from './components/FormArticle';
import { FormStatMargePrest } from './components/FormStatMargePrest';
import { FormStatMargeIndicateur } from './components/FormStatMargeIndicateur';
import { FormJournalFactureTrim } from './components/FormJournalFactureTrim';
import { FormEnquete } from './components/FormEnquete';
import { FormStatQtePrest } from './components/FormStatQtePrest';
import { FormStatQteIndicateur } from './components/FormStatQteIndicateur';
import { FormTypeDepense } from './components/FormTypeDepense';
import { FormDepense } from './components/FormDepense';
import { FormStatConsoBudget } from './components/FormStatConsoBudget';
import { FormStatMargeDomaine } from './components/FormStatMargeDomaine';
import { FormResultatEnquete } from './components/FormResultatEnquete';
import { FormJournalResultatEnquete } from './components/FormJournalResultatEnquete';
import {FormRestriction}  from './components/FormRestriction';
import {FormStatRapportagePrestMois}  from './components/FormStatRapportagePrestMois';
import {DashboardIndicateurs}  from './components/DashboardIndicateurs';


/*

import {FormStatConsoBudget}  from './components/FormStatConsoBudget';
import {FormStatConsoBudget}  from './components/FormStatConsoBudget';
import {FormStatConsoBudget}  from './components/FormStatConsoBudget';
*/




import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import Appservice from './service/FbrService';

const App = () => {
    const appservice = new Appservice();
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('assets/layout/images/logo-dark.svg')


    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;

    var menu = [];
    var profil_suer = '';
    var userconnected = appservice.get_userconnected();

    if (userconnected !== null && userconnected !== undefined) {
        profil_suer = userconnected.profilUser;
    }
    if (profil_suer == 'Administrateur') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion payements", icon: "pi pi-fw pi-money-bill", to: '/payements' },
                    { label: "Gestion enquête", icon: "pi pi-fw pi-thumbs-up", to: '/enquete' },
                    { label: "Dépenses", icon: "pi pi-fw pi-money-bill", to: '/depense' },
                    { label: "Gestion échantillonage", icon: "pi pi-fw pi-bookmark", to: '/echantillonage' },

                ]
            },
            { separator: true },
            {
                label: "Paramétrage", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Famille indicateur", icon: "pi pi-fw pi-cog", to: '/familles' },
                    { label: "Indicateurs", icon: "pi pi-fw pi-cog", to: '/indicateurs' },
                    { label: "Banques", icon: "pi pi-fw pi-cog", to: '/banques' },
                    { label: "Catégorie acteur", icon: "pi pi-fw pi-cog", to: '/categories' },
                    { label: "Type acteur", icon: "pi pi-fw pi-cog", to: '/typeprest' },
                    { label: "Sites", icon: "pi pi-fw pi-cog", to: '/sites' },
                    { label: "Acteurs", icon: "pi pi-fw pi-cog", to: '/acteurs' },
                    { label: "Utilisateurs", icon: "pi pi-fw pi-cog", to: "/utilisateurs" },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                    { label: "Types dépenses", icon: "pi pi-fw pi-cog", to: '/typedepense' },
                    { label: "Restrictions", icon: "pi pi-fw pi-cog", to: '/restrictions' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Edition", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                            { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                            { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                            { label: "Recommandations", icon: "pi pi-fw pi-print", to: '/journalreco' },
                            { label: "Journal payements", icon: "pi pi-fw pi-print", to: '/journalpayement' },
                            { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                            { label: "Journal traces", icon: "pi pi-fw pi-print", to: '/journaltrace' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-bar",
                        items: [
                            { label: "Rap. par acteur", icon: "pi pi-fw pi-chart-bar", to: '/rapportageprest' },
                            { label: "Rap. par CR", icon: "pi pi-fw pi-chart-bar", to: '/rapportagecrd' },
                            { label: "Rap. par préfecture", icon: "pi pi-fw pi-chart-bar", to: '/rapportagepref' },
                            { label: "Rap. par region", icon: "pi pi-fw pi-chart-bar", to: '/rapportageregion' },
                            { label: "TD par acteur", icon: "pi pi-fw pi-chart-bar", to: '/margeprest' },
                            { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                            { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                            // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                            { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                            { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },
                            { label: "Rapportage par mois", icon: "pi pi-fw pi-chart-bar", to: '/statrapportagemois' },
                            { label: "Indicateurs phares", icon: "pi pi-fw pi-chart-bar", to: '/dashindicateurs' },

                            
                        ]
                    },
                ]
            },

        ];
    } else if (profil_suer == 'Coordinateur') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion enquête", icon: "pi pi-fw pi-thumbs-up", to: '/enquete' },
                    { label: "Gestion dépenses", icon: "pi pi-fw pi-money-bill", to: '/depense' },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Edition", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                            { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                            { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                            { label: "Recommandations", icon: "pi pi-fw pi-print", to: '/journalreco' },
                            { label: "Journal payements", icon: "pi pi-fw pi-print", to: '/journalpayement' },
                            { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-bar",
                        items: [
                            { label: "Rap. par acteur", icon: "pi pi-fw pi-chart-bar", to: '/rapportageprest' },
                            { label: "Rap. par CR", icon: "pi pi-fw pi-chart-bar", to: '/rapportagecrd' },
                            { label: "Rap. par préfecture", icon: "pi pi-fw pi-chart-bar", to: '/rapportagepref' },
                            { label: "Rap. par region", icon: "pi pi-fw pi-chart-bar", to: '/rapportageregion' },
                            { label: "TD par acteur", icon: "pi pi-fw pi-chart-bar", to: '/margeprest' },
                            { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                            { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                            // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                            { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                            { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },
                            { label: "Rapportage par mois", icon: "pi pi-fw pi-chart-bar", to: '/statrapportagemois' },
                            { label: "Indicateurs phares", icon: "pi pi-fw pi-chart-bar", to: '/dashindicateurs' },

                        ]
                    },
                ]
            },
        ];
    } else if (profil_suer == 'OBC') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion dépenses", icon: "pi pi-fw pi-money-bill", to: '/depense' },
                    { label: "Gestion échantillonage", icon: "pi pi-fw pi-bookmark", to: '/echantillonage' },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                    { label: "Recommandations", icon: "pi pi-fw pi-print", to: '/journalreco' },
                    { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-bar",
                        items: [
                            { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                            { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                            // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                            { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                            { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },

                        ]
                    },
                ]
            },
        ];
    } else if (profil_suer == 'Payeur') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion payements", icon: "pi pi-fw pi-money-bill", to: '/payements' },
                    { label: "Gestion enquête", icon: "pi pi-fw pi-thumbs-up", to: '/enquete' },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-print",
                items: [
                    { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                    { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                    { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                    { label: "Journal payements", icon: "pi pi-fw pi-print", to: '/journalpayement' },
                    { label: "Rap. par acteur", icon: "pi pi-fw pi-chart-bar", to: '/rapportageprest' },
                    { label: "Rap. par CR", icon: "pi pi-fw pi-chart-bar", to: '/rapportagecrd' },
                    { label: "Rap. par préfecture", icon: "pi pi-fw pi-chart-bar", to: '/rapportagepref' },
                    { label: "Rap. par region", icon: "pi pi-fw pi-chart-bar", to: '/rapportageregion' },
                    // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                    { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                    { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },
                    { label: "Rapportage par mois", icon: "pi pi-fw pi-chart-bar", to: '/statrapportagemois' },

                ]
            },

        ];
    } else if (profil_suer == 'Agent de saisie') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion enquête", icon: "pi pi-fw pi-thumbs-up", to: '/enquete' },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-print",
                items: [
                    { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                    { label: "Journal des recom.", icon: "pi pi-fw pi-print", to: '/journalreco' },
                    { label: "Rap. par acteur", icon: "pi pi-fw pi-print", to: '/rapportageprest' },
                    { label: "Rap. par CR", icon: "pi pi-fw pi-print", to: '/rapportagecrd' },
                    { label: "Rap. par préfecture", icon: "pi pi-fw pi-print", to: '/rapportagepref' },
                    { label: "Rap. par region", icon: "pi pi-fw pi-print", to: '/rapportageregion' },
                    { label: "TD par acteur", icon: "pi pi-fw pi-chart-bar", to: '/margeprest' },
                    { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                    { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                ]
            },

        ];
    } else if (profil_suer == 'AGFA') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion payements", icon: "pi pi-fw pi-money-bill", to: '/payements' },
                    { label: "Gestion enquête", icon: "pi pi-fw pi-thumbs-up", to: '/enquete' },
                    { label: "Dépenses", icon: "pi pi-fw pi-money-bill", to: '/depense' },
                    { label: "Gestion échantillonage", icon: "pi pi-fw pi-bookmark", to: '/echantillonage' },
                ]
            },
            { separator: true },
            {
                label: "Paramétrage", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Famille indicateur", icon: "pi pi-fw pi-cog", to: '/familles' },
                    { label: "Indicateurs", icon: "pi pi-fw pi-cog", to: '/indicateurs' },
                    { label: "Catégorie acteur", icon: "pi pi-fw pi-cog", to: '/categories' },
                    { label: "Acteurs", icon: "pi pi-fw pi-cog", to: '/acteurs' },
                    { label: "Utilisateurs", icon: "pi pi-fw pi-cog", to: "/utilisateurs" },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                    { label: "Types dépenses", icon: "pi pi-fw pi-cog", to: '/typedepense' },

                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Edition", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                            { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                            { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                            { label: "Recommandations", icon: "pi pi-fw pi-print", to: '/journalreco' },
                            { label: "Journal payements", icon: "pi pi-fw pi-print", to: '/journalpayement' },
                            { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                            { label: "Journal traces", icon: "pi pi-fw pi-print", to: '/journaltrace' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-bar",
                        items: [
                            { label: "Rap. par acteur", icon: "pi pi-fw pi-chart-bar", to: '/rapportageprest' },
                            { label: "Rap. par CR", icon: "pi pi-fw pi-chart-bar", to: '/rapportagecrd' },
                            { label: "Rap. par préfecture", icon: "pi pi-fw pi-chart-bar", to: '/rapportagepref' },
                            { label: "Rap. par region", icon: "pi pi-fw pi-chart-bar", to: '/rapportageregion' },
                            { label: "TD par acteur", icon: "pi pi-fw pi-chart-bar", to: '/margeprest' },
                            { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                            { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                            // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                            { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                            { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },
                            { label: "Rapportage par mois", icon: "pi pi-fw pi-chart-bar", to: '/statrapportagemois' },
                            { label: "Indicateurs phares", icon: "pi pi-fw pi-chart-bar", to: '/dashindicateurs' },

                        ]
                    },
                ]
            },

        ];
    } else if (profil_suer == 'Prestataire') {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Gestion rapportages", icon: "pi pi-fw pi-play", to: '/rapportage' },
                    { label: "Gestion dépenses", icon: "pi pi-fw pi-money-bill", to: '/depense' },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                    { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                    { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                    { label: "Recommandations", icon: "pi pi-fw pi-print", to: '/journalreco' },
                    { label: "Journal payements", icon: "pi pi-fw pi-print", to: '/journalpayement' },
                    { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-bar",
                        items: [
                            { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                            { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                            // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                            { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                            { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },

                        ]
                    },
                ]
            },
        ];
    } else {
        menu = [
            {
                label: "Opération", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Tableau de bord", icon: "pi pi-fw pi-home", to: "/dashboard" },
                    { label: "Contenus", icon: "pi pi-fw pi-cog", to: '/contenus' },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-cog",
                items: [
                    { label: "Journal des factures", icon: "pi pi-fw pi-print", to: '/journalfacture' },
                    { label: "Facture par trimestre", icon: "pi pi-fw pi-print", to: '/journalfacturetrim' },
                    { label: "Journal rapportages", icon: "pi pi-fw pi-print", to: '/journalrapportage' },
                    { label: "Journal recomm", icon: "pi pi-fw pi-print", to: '/journalreco' },
                    { label: "Rap. par acteur", icon: "pi pi-fw pi-chart-bar", to: '/rapportageprest' },
                    { label: "Rap. par CR", icon: "pi pi-fw pi-chart-bar", to: '/rapportagecrd' },
                    { label: "Rap. par préfecture", icon: "pi pi-fw pi-chart-bar", to: '/rapportagepref' },
                    { label: "Rap. par region", icon: "pi pi-fw pi-chart-bar", to: '/rapportageregion' },
                    { label: "TD par acteur", icon: "pi pi-fw pi-chart-bar", to: '/margeprest' },
                    { label: "TD par indicateur", icon: "pi pi-fw pi-chart-bar", to: '/margeindicateur' },
                    { label: "TD par domaine", icon: "pi pi-fw pi-chart-bar", to: '/margedomaine' },
                    // { label: "Quantité par nom", icon: "pi pi-fw pi-chart-bar", to: '/statquantite' },
                    { label: "Tendances par mois", icon: "pi pi-fw pi-chart-bar", to: '/statquantiteindicateur' },
                    { label: "Stat. des conso", icon: "pi pi-fw pi-chart-bar", to: '/statconso' },
                    { label: "Journal des enquêtes", icon: "pi pi-fw pi-print", to: '/journalenquete' },
                    { label: "Rapportage par mois", icon: "pi pi-fw pi-chart-bar", to: '/statrapportagemois' },
                    { label: "Indicateurs phares", icon: "pi pi-fw pi-chart-bar", to: '/dashindicateurs' },

                ]
            },

        ];
    }

    const routers = [
        { path: '/dashboard', component: Dashboard, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },
        { path: '/restrictions', component: FormRestriction, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Restrictions' }] } },

        { path: '/typedepense', component: FormTypeDepense, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Types dépenses' }] } },
        { path: '/depense', component: FormDepense, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Dépenses' }] } },

        { path: '/utilisateurs', component: FormUtilisateur, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Comptes utilisateurs' }] } },
        { path: '/sites', component: FormSite, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Sites' }] } },
        { path: '/acteurs', component: FormPrestataire, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Acteurs' }] } },
        { path: '/formules', component: FormFormuleEvaLigneFormule, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Formules' }] } },
        { path: '/indicateurs', component: FormTypeService, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'indicateurs' }] } },
        { path: '/familles', component: FormFamilleIndicateur, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Familles indicateur' }] } },
        { path: '/categories', component: FormCategoriePrest, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Catégories acteur' }] } },
        { path: '/banques', component: FormBanque, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Banques' }] } },
        { path: '/typeprest', component: FormTypePrest, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Type acteur' }] } },
        { path: '/chpwd', component: FormChpwd, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Changement de mot de passe' }] } },
        { path: '/contenus', component: FormArticle, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Contenus' }] } },


        { path: '/payements', component: FormReglerObjectif, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Payements' }] } },
        { path: '/rapportage', component: FormFacture, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Rapportage' }] } },
        { path: '/journalfacture', component: FormJournalFacture, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal des factures' }] } },
        { path: '/enquete', component: FormEnquete, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Enquêtes' }] } },
        { path: '/echantillonage', component: FormResultatEnquete, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Echantillonage' }] } },


        { path: '/journalfacturetrim', component: FormJournalFactureTrim, meta: { breadcrumb: [{ parent: 'Edition', label: 'Factures par trimestre' }] } },
        { path: '/journalenquete', component: FormJournalResultatEnquete, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal des enquêtes' }] } },




        { path: '/journalrapportage', component: FormJournalRapportage, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal rapportages' }] } },
        { path: '/journalreco', component: FormJournalReco, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal des recommandations' }] } },
        { path: '/journaltrace', component: FormJournalTrace, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal des traces' }] } },
        { path: '/journalpayement', component: FormJournalPayement, meta: { breadcrumb: [{ parent: 'Edition', label: 'Journal des payements' }] } },

        { path: '/rapportageprest', component: FormStatRapportagePrest, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Rapportages par acteur' }] } },
        { path: '/rapportagecrd', component: FormStatRapportageCRD, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Rapportages par CR' }] } },
        { path: '/rapportagepref', component: FormStatRapportagePref, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Rapportages par Préfectures' }] } },
        { path: '/rapportageregion', component: FormStatRapportageRegion, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Rapportages par Region' }] } },
        { path: '/margeprest', component: FormStatMargePrest, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'TD par acteur' }] } },
        { path: '/margeindicateur', component: FormStatMargeIndicateur, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'TD par indicateur' }] } },
        { path: '/statquantite', component: FormStatQtePrest, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Indicateurs quantitatifs' }] } },
        { path: '/statquantiteindicateur', component: FormStatQteIndicateur, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Tendance des Indicateurs' }] } },
        { path: '/statconso', component: FormStatConsoBudget, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des consos' }] } },
        { path: '/margedomaine', component: FormStatMargeDomaine, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'TD par domaine' }] } },
        { path: '/statrapportagemois', component: FormStatRapportagePrestMois, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Rapportage par mois' }] } },
        { path: '/dashindicateurs', component: DashboardIndicateurs, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Dashboard des indicateurs phares' }] } },


        

        

    ];

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme)

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.svg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme)
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);
        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById("app-logo");
        const mobileLogoLink = document.getElementById("logo-mobile");
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const footerLogoLink = document.getElementById("footer-logo");
        setLogoUrl(`assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.svg`);

        if (appLogoLink) {
            appLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.svg`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        }
        else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };


    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };


    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false)
        }
    };


    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay': menuMode === "overlay",
            'layout-static': menuMode === "static",
            'layout-slim': menuMode === "slim",
            'layout-sidebar-dim': colorScheme === "dim",
            'layout-sidebar-dark': colorScheme === "dark",
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === "static",
            'p-input-filled': inputStyle === "filled",
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '');

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <div className="layout-content-wrapper">
                <AppTopBar routers={routers} topbarNotificationMenuActive={topbarNotificationMenuActive} topbarUserMenuActive={topbarUserMenuActive} onMenuButtonClick={onMenuButtonClick} onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick} onTopbarUserMenu={onTopbarUserMenuButtonClick} onRightMenuClick={onRightMenuButtonClick} onRightMenuButtonClick={onRightMenuButtonClick}></AppTopBar>
                <div className="layout-content">
                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                            }
                            return <Route key={`router${index}`} path={router.path} component={router.component} />
                        })
                    }
                </div>
                <AppFooter />
            </div>
            <AppMenu model={menu} menuMode={menuMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} onMenuClick={onMenuClick} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppMenu>
            <div className="layout-mask modal-in"></div>
        </div>
    );
}

export default App;
